<template>
  <div>
    <img
      v-if="data.imageUrl"
      class="image"
      :src="data.imageUrl"
      alt=""
    />
    <img
      v-if="!data.imageUrl"
      class="image"
      src="../../assets/images/ytss_logo.jpg"
      alt=""
    />

    <!-- 基本信息 -->
    <div class="baseInfo">
      <div class="infotag">基本信息</div>

      <div class="infoItem">
        <div class="infodetail">
          <div class="key">产品名称</div>
          <div class="value">{{ data.productName }}</div>
        </div>
        <div class="line">
       
        </div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">出品企业</div>
          <div class="value">{{ data.supplierName }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem">
        <div class="infodetail">
          <div class="key">商品规格</div>
          <div class="value">{{ data.productSpec }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="infoItem" v-if="data.address">
        <div class="infodetail">
          <div class="key">产地</div>
          <div class="value">{{ data.address }}</div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <!-- 中心认证 -->
    <!-- <div class="centerContainer">
      <div class="centerTextContainer">
        <img class="gs" src="../../assets/images/project4/gs.png" alt="">
        <div class="centerText">中国物品编码中心认证</div>
      </div>
      <div class="detail">
        点击查看详情
      </div>
    </div> -->
    <!-- 批次信息 -->
    <div class="batchContainer">
      <div class="batchTitle">批次信息</div>
      <div class="batchInfo">
        <div class="bacthKey">产品批次</div>
        <input class="batchValue"   v-model="batchValue"  @keyup.enter="search">

      </div>
     
      <!-- 有批次信息 -->
      <div v-if="batchData&&batchData.status==0">
      <div class="infoContainer">
        <div class="info_bottom">
          <img
            src="../../assets/images/project1/tick.png"
            style="width: 41px; height: 41px; "  
          />
          <div class="info_bottomLeft">

            <p class="title">真品验证通过！</p>
            <p class="positioned">查询次数: {{ batchData.data.searchNumber }}次 </p> <br>
            <p class="positioned positioned-error" v-if="batchData.data.exceedScanNumber && batchData.data.exceedScanNumber==1">查询次数过多 ，请核实</p>
      
            <p class="positioned">批次编号:  {{ batchData.data.batchNo }}</p><br>
            <p class="positioned positioned-error" v-if="batchData.data.exceedPopFarmDays && batchData.data.exceedPopFarmDays==1">距离出栏时间较久，请核实</p>
          </div>
        </div>
      </div>
      </div>
      <!--无批次信息  -->
      <div class="withoutBatchContainer" v-if="batchData&&batchData.status!=0">
        <img class="withoutBatchImg" src="../../assets/images/project4/error.png" alt="">
        <div class="withoutBatchText">批次错误，请核实</div>
      </div>
    </div>
    
    <!-- 产地定位 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="latitude">
      <div class="addressTag">产地定位</div>
      <div class="placeAddress">
        <div class="address" id="container"></div>
        <div class="addressInfo" v-if="data.address ">地址：{{ data.address }}</div>
        <div v-if="mobile" class="addressInfo" >联系方式：{{ mobile }}</div>
      </div>
    </div>
    <!-- 检测报告 -->
    <div
      class="baseinfo"
      style="margin-top: 26px"
      v-if="
        batchData&&batchData.status==0 
      "
    >
      <div class="addressTag">检测报告</div>
      <div class="placeAddress">
        <!-- 轮播 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-if="batchData.data.checkReport"
            ><img
              class="bannerImg"
              :src="batchData.data.checkReport"
              alt=""
              @click="reportClick('checkReport')"
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 区块链信息 -->
    <div class="baseinfo" style="margin-top: 26px" v-if="data.formatFattenPushPopTime">
      <div class="addressTag">区块链信息</div>


      <div class="area" id="areaCanvasImg">
        <div class="clickDownLaod" @click="imagePreview(url)">点击放大 ></div>
        <div class="areaContainer" >
          <div class="areaChidBg" ref="canvasImg">
            <img   src="../../assets/images/project4/areaBlueOrder.png" alt=""  />
            <div   class="img" >  
              <div class="firstLine">广东一天膳事食品安全科技有限责任公司</div>
              <div class="secondLine">{{ data.manufactureOrderContractId }}</div>
              <div class="thirdLine">{{ data.formatFattenPushPopTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="area" style="background: #fff; padding: 0 9px">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            margin-right: 7px;
          "
        >
          <div
            style="color: #b44130; font-size: 13px"
            @click="imagePreview(url)"
          >
            点击放大 >
          </div>
        </div>
        <div class="areaimg" ref="canvasImg"  >
          <div class="areaimgText" >
            <div>海南省文昌市畜牧兽医服务中心</div>
            <div class="only">{{ data.manufactureOrderContractId }}</div>
            <div>{{ data.formatFattenPushPopTimeTime }}</div>

          </div>
        </div>
      </div> -->
    </div>

    <!-- 底部技术支持 -->
    <div class="bottom">
      <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt="">
    </div>
     <!-- 加载loading -->
     <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "产品批次查验中..."
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ImagePreview ,Loading} from "vant";
import html2canvas from "html2canvas";
import { post } from "../../utils/http";
Vue.use(ImagePreview).use(Loading);
export default {
  name: "HomeProductComponent",
  props: [
    "data",
    "latitude",
    "longitude",
    "url",
    "mobile"
  ],
  data() {
    return {
      imgUrl:'',
      loadingAll:false,
      batchValue:'',
      batchData:''
    };
  },
  mounted() {
    console.log(this.data);
    this.initMap();
  },
  methods: {
    search(){
      this.loadingAll=true
      if(this.batchValue==''){
        this.batchValue=null
       }
      const url =`/api/public/v2/manufacture/tracing/${this.data.businessId}/${this.data.productId}/${this.batchValue}/findProductionByBatchNo`
    
      post(url, {}, false).then(res=>{
        // 两个个参数分别代表，是否有阶段数，是否有批次，是否有检测报告
          this.batchData=res
          this.$emit('getstageList', res.data.stageList);
          this.loadingAll=false
      }).catch(err=>{
        this.batchData=err
        this.$emit('getstageList', null,false);
        this.loadingAll=false
      })
    },
    reportClick(img){
      let arr=[]
      if(this.batchData.data.checkReport){
        arr.push(this.batchData.data.checkReport)
      }
      let index=0
      if(arr.length==1){
        index=0
      }
      ImagePreview({
        images: arr,
        startPosition: index,
      });

    },
    async createImage() {
      try {
        // const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        // const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        // this.imgUrl = image;
        // ImagePreview([image]);
        
        
        var canvas2 = document.createElement("canvas");
            let _canvas = this.$refs.canvasImg;
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);
            //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
            canvas2.width = w * 2;
            canvas2.height = h * 2;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";
            const context = canvas2.getContext("2d");
            context.scale(1,1);
            context.fillStyle = '#FFFFFF'
            context.fillRect(0, 0, canvas2.width, canvas2.height);


            const config = {
            backgroundColor: null,
            canvas: canvas2,
            useCORS: true,//配置允许跨域
            scale: 2,
            dpi: 800 // 处理模糊问题
            };
            console.log("获取指定的宽高", w, h, canvas2);

            const canvas = await html2canvas(this.$refs.canvasImg,config).then(canvas => {
            
            
            try {
                // 转成图片，生成图片地址
                this.imgUrl = canvas.toDataURL("image/png");  
                    ImagePreview([this.imgUrl]);
                } catch (e) {
                alert("图片跨域,保存失败");
                }


            });

      this.loadingAll=false
      } catch (e) {
        this.loadingAll=false
        throw new Error(e);
      }
    },
    imagePreview(url) {
      this.loadingAll=true
      this.createImage();
      
    },
    initMap() {
      if(!this.latitude) return
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);
      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.image {
  width: 323px;
  height: 221px;
  margin: 0 auto;
  margin-top: 16px;
  display: block;
  border-radius: 16px;
  object-fit: cover;
}
.dateImgClass {
  width: 152px;
  height: 44px;
  margin: 0 auto;
  margin-top: 11px;
  background: url("../../assets/images/project1/indexdate.png");
  background-size: cover;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding-left: 16px;
  padding-right: 35px;
  align-items: center;
  padding-bottom: 11px;
  padding-top: 9px;
  font-weight: 500;
  // line-height: 33px;
}
.dateText {
  font-size: 12px;
  line-height: 14px;
}
.progress {
  position: relative;
  // border: 1px solid #000;
  width: 230px;
  height: 34px;
  margin: 0 auto;
  margin-top: 24px;
}



.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  margin-top: 27px;
  padding: 0px 0 16px 0;
  position: relative;
  border-radius: 6px;
  display: inline-block;
}
// 中心认证
.centerContainer{
  width: 267px;
  margin: 0 auto;
  height: 90px;
    background: url("../../assets/images/project4/center.png") no-repeat center /
    cover;
    margin-top: 22px;
    overflow: hidden;
    .centerTextContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
      .gs{
        width: 36px;height: 27px;
      
      }
      .centerText{
        font-size: 16px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .detail{
      margin: 0 auto;
      border-radius: 6px;
      background-color: rgb(238 242 193);;
      color: #006000;
      width: 94px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin-top: 7px;
      font-size: 12px;
    }
}
// 批次信息
.batchContainer{
  width: 319px;
  // height: 71px;
  margin: 0 12px;
  background-color: #fff;
  margin-top: 34px;
  // padding: 24px 0 21px 0;
  border-radius: 8px;
  display: inline-block;
  .batchTitle{
    position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    
    rgb(59 173 54),
    rgb(181 210 62)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: -11px;
  }
  .batchInfo{
    display: flex;
    margin-top: 14px;
    margin-bottom: 21px;
    justify-content: center;
    align-items: center;
    .bacthKey{
        color: #006000;
        font-size: 12px;
        letter-spacing: 1px;
    }
    .batchValue{
        width: 215px;
        height: 26px;
        border: 1px solid #000;
        text-align: center;
        font-size: 12px;
    //     display: flex;
    // justify-content: center;
    // align-items: center;
    margin-left: 8px;
    border-radius: 6px;
    background-color: #f7f7f7;
    }
  }
}
// 有批次信息
.info_bottom {
  display: flex;
  background: url("../../assets/images/project1/passImg.png") no-repeat center /
    cover;
  border-radius: 6px;
  padding: 0px 19px 10px 19px;
  color: #fff;
  align-items: center;

  .info_bottomLeft {
    width: 250px;
    margin-left: 12px;
    margin-top: 14px;
    overflow: hidden;
    .info_bottomTitle {
      font-size: 15px;
      color: #231815;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .info_bottomLeftDetail {
      display: flex;
      color: #000;
      font-size: 10px;
      color: #231815;
      line-height: 17px;
    }
    label,p{
      margin: 0px auto;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .title{
      font-size: 15px;
      margin-bottom: 8px;
      font-weight: bold;
    }
    .positioned{
      
      margin-bottom: 2px;
      display: inline-block;
      // line-height: 13px;
      font-size: 11px;
    }
    .positioned-error{

    color: red;
    }

  }
}
// 无批次信息
.withoutBatchContainer{
  width: 319px;
  height: 98px;
  background-color: #f6dbde;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  .withoutBatchImg{
      width: 41px;
      height: 41px;

  }
  .withoutBatchText{
    color: #c30d22;
    font-size: 16px;
    margin-left: 8px;
    font-weight: 600;
  }
}
.infotag {
  position: relative;
  width: 95px;
  height: 23px;
  // background: url("../../assets/images/project1/infoTag.png") no-repeat center /
  //   cover;
  background-image: linear-gradient(
    -135deg,
    
    rgb(59 173 54),
    rgb(181 210 62)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: -11px;
}
.infoItem {
  width: 100%;
  margin-top: 15px;
  padding: 0 16px;
}
.line {
  height: 0.5px;
  background-color: rgb(139, 202, 157);
  margin-top: 10px;
}
.infodetail {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
// 产地定位
.addressTag {
  position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    
    rgb(59 173 54),
    rgb(181 210 62)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-left: 12px;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  margin: 0 12px;
  // height: 262px;
  margin-top: -11px;
  z-index: 1;
  background-image: linear-gradient(to bottom ,rgb(181 210 62),rgb(59 173 54),);
  overflow: hidden;
  border-radius: 6px;
  padding-bottom: 28px;
}
.address {
  width: 287px;
  height: 193px;
  margin: 29px auto 0px auto;
  border-radius: 6px;
  overflow: hidden;
  // margin-bottom: 20px;
  //margin-top: 29px;
  //   border: 1px solid #000;
}
.addressInfo {
  font-size: 13px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin: 0 auto;
  margin-top: 14px;
  margin-bottom: -14px;
  // margin-top: 14px;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_blue_order.png") no-repeat
  //   center / cover;
  background: url("../../assets/images/project1/areaBlueOrder.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}
.areaimgText{
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;

  // padding-top: 18.2vw;
  // padding-left: 24vw;
  // padding-right: 7vw;
  text-align: right;
  div{
    font-size: 12px;
    word-break: break-all; 
    color: #231815;
line-height: 16px;
  }
  .only{
    margin: 2% 0 1.9% 0;   
  }
    
}
.area {
  
  margin: 0 12px;
  margin-top: -11px;
  z-index: 1;
  overflow: hidden;
  border-radius: 6px;
  background: #fff; 

  .clickRedText{
    color: #d82a1f;
    font-size: 13px;
    // padding: 0 12px;
    text-align: right;
    margin-bottom: 7px;
    
  }
  .clickDownLaod {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-right: 12px;
    color: #40AA6C; font-size: 13px
  }
  .areaContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    .areaChidBg{

      position: relative;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
      margin: auto; 
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      // padding-top: 69px; //18.2
      // padding-left: 90px;
      // padding-right: 25px;
      font-size: 11px;
      word-break: break-all;
      color: #231815;
      text-align: right;
      line-height: 16px;
      .firstLine{
        width: 200px;
        position: absolute;
        top: 67.2px;
        left: 80px;
      }
      .secondLine {
        width: 200px;
        position: absolute;
        top: 90px;
        left: 80px;
      }
      .thirdLine{
        width: 200px;
        position: absolute;
        top: 172.75px;
        left: 80px;
      }
    }
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  width: 287px !important;
  height: 220px !important;
  margin: 0 auto;
  line-height: 150px;
  text-align: center;
  margin-top: 24px;
  .bannerImg {
    max-width: 200px;
        height: 100%;
        max-height: 200px;
        object-fit: contain;
  }
}
.key {
  width: 120px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
.value {
  font-weight: 400;
  font-size: 13px;
  color: #231815;
  line-height: 20px;
  text-align: right;
  flex: 1;
}
</style>